import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-black shadow-lg">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          <a href="/" className="text-white text-xl font-semibold">
            Rakayib For Car Services
          </a>
          <ul className="flex space-x-6">
            <li><a href="#" className="text-gray-300 hover:text-white">About Rakayib</a></li>
            <li><a href="#" className="text-gray-300 hover:text-white">Our Services</a></li>
            <li><a href="#" className="text-gray-300 hover:text-white">Contact Us</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
