import React from 'react';
import service01 from '../images/service01.png'
import service02 from '../images/service02.png'
import service03 from '../images/service03.png'


const ServicesSection = () => {
    const services = [
        {
            title: "Car Wash",
            description: "Thorough exterior and interior cleaning",
            image: service01,
        },
        {
            title: "Detailing",
            description: "Comprehensive cleaning and restoration",
            image: service02,
        },
        {
            title: "Interior Cleaning",
            description: "Deep cleaning of car's interior surfaces",
            image: service03,
        },
    ];

    return (
        <>
            <div className="w-full h-fit bg-white ">
                <h1 className="text-black text-center text-5xl font-black p-5">
                    <span className="">Our Serv</span>ices </h1>
            </div>

            <div className="mx-4  mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">

                {services.map((service, index) => (
                    <div
                        key={index}
                        className="bg-black rounded-md p-4 text-center shadow-md"
                    >
                        <img
                            src={service.image}
                            alt={service.title}
                            className="w-full  mb-2"
                        />
                        <h3 className="text-white text-xl font-semibold mb-2">{service.title}</h3>
                        <p className="text-slate-100">{service.description}</p>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ServicesSection;
