import React from 'react';

const ContactUsSection = () => {
    return (
        <section className="bg-gray-100 py-16">
            <div className="w-full h-fit ">
                <h1 className="text-black text-center text-5xl font-black p-5">
                    <span className="">Contact US</span> </h1>
            </div>
            <div className="container mx-auto flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 p-8">
                    {/* Google Maps location */}
                    <iframe
                        title="Google Maps Location"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14836.588521306583!2d39.1652367!3d21.6191897!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d1548a249f9d%3A0x8a4199dc235041be!2z2LHZg9in2KbYqCDZhNiu2K_Zhdin2Kog2YTZhNiz2YrYp9ix2KfYqg!5e0!3m2!1sen!2ssa!4v1692017905869!5m2!1sen!2ssa"
                        width="600"
                        height="450"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        allowFullScreen
                    />
                </div>
                <div className="md:w-1/2 p-8">
                    {/* Contact Form */}
                    <form className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                        <div className="mb-4">
                            <label htmlFor="name" className=" block text-gray-700">Name</label>
                            <input type="text" id="name" className="border-2 border-black w-full rounded-lg border-gray-300 p-2" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className=" block text-gray-700">Email</label>
                            <input type="email" id="email" className="border-2 border-black w-full rounded-lg border-gray-300 p-2" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className=" block text-gray-700">Message</label>
                            <textarea id="message" rows="4" className="border-2 border-black w-full rounded-lg border-gray-300 p-2" />
                        </div>
                        <button
                            type="submit"
                            className="bg-black hover:bg-gray-600 text-white py-2 px-4 rounded-lg"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUsSection;
