import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import ContactUsSection from './components/ContactUsSection';
import Footer from './components/Footer';
import AboutUsSection from './components/AboutUsSection';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <ServicesSection />
      <AboutUsSection />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default App;
