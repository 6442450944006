import React from 'react';
import logo from '../images/logo.png'
import twitter from '../images/twitter.png'
import instagram from '../images/instagram.png'
import snapchat from '../images/snapchat.png'
import tiktok from '../images/tiktok.png'
import linkedin from '../images/linkedin.png'

const Footer = () => {
    return (
        <footer className="bg-black py-8 text-white">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center mb-4 md:mb-0 ml-5">
                    <img
                        src={logo}
                        alt="Company Logo"
                        className="w-20 h-20 rounded-lg bg-white p-1 mr-4"
                    />
                    <p className="text-sm">© 2023 Rakayib For Cars Services. All rights reserved.</p>
                </div>
                <div className="bg-white flex space-x-6 p-2 rounded-lg mr-5 ml-5">
                    <a href="#" className="bg-white">
                        <img className="h-12 w-12" src={twitter} ></img>
                    </a>
                    <a href="#" className="bg-white">
                        <img className="h-12 w-12" src={instagram} ></img>
                    </a>
                    <a href="#" className="bg-white">
                        <img className="h-12 w-12" src={snapchat} ></img>
                    </a>
                    <a href="#" className="bg-white">
                        <img className="h-12 w-12" src={tiktok} ></img>
                    </a>
                    <a href="#" className="bg-white">
                        <img className="h-12 w-12" src={linkedin} ></img>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;