import React from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import service01 from '../images/service01.png'
import service02 from '../images/service02.png'
import service03 from '../images/service03.png'
import logo from '../images/logo.png'
import './hid.css'

const AboutUsSection = () => {
    return (
        <>
            <div className="w-full h-fit bg-white mt-4 ">
                <h1 className="text-black text-center text-5xl font-black p-5">
                    <span className="bg-black text-white rounded-xl px-4">About</span> US </h1>
            </div>
            <div className='hidy2 relative bg-black h-screen w-full overflow-hidden my-8  '>

                <Parallax className='hidy2 overlay ' pages={3}>
                    <ParallaxLayer
                        offset={0}
                        speed={1}
                        factor={2}
                        style={{
                            backgroundImage: `url(${service01})`,
                            backgroundSize: 'cover',
                        }}>
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={1}
                        speed={1}
                        factor={2}
                        style={{
                            backgroundImage: `url(${service02})`,
                            backgroundSize: 'cover',
                        }}
                    >
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={2}
                        speed={1}
                        factor={2}
                        style={{
                            backgroundImage: `url(${service03})`,
                            backgroundSize: 'cover',
                        }}
                    >

                    </ParallaxLayer>

                    <ParallaxLayer className='flex justify-center'
                        sticky={{ start: 0.5, end: 0.5 }}
                    >
                        <img className='h-40 w-40' src={logo} />

                    </ParallaxLayer>
                    <ParallaxLayer className='flex justify-center'
                        sticky={{ start: 0.9, end: 0.9 }}
                    >
                        <h1 className='text-center text-white text-2xl font-bold'>Rakayib is Dedicated To Providing Top-Quality
                            Car Services {<br></br>} To Our Valued Customers.{<br></br>} With a Rich History And
                            a Clear Vision For The Future, We Strive To Deliver Excellence In Everything
                            we do.</h1>
                    </ParallaxLayer>

                </Parallax>
            </div>

        </>

    );
};

export default AboutUsSection;
