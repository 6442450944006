import React from 'react';
import banner01 from '../images/banner01.png'


const HeroSection = () => {

    return (
        <section className="bg-gray-100 py-8">
            <div className="container mx-auto flex flex-col lg:flex-row items-center">
                <div className="lg:w-1/2 p-8">
                    <h1 className="text-5xl font-bold  mb-4">
                        Welcome to Rakayib For Car Services
                    </h1>
                    <p className="text-lg text-gray-700 mb-6">
                        Your trusted partner for professional car cleaning and detailing services.
                    </p>
                    <button className="bg-black hover:bg-gray-600 text-white py-2 px-6 rounded-lg">
                        Learn More
                    </button>
                    <button className="bg-black hover:bg-gray-600 text-white py-2 px-6  ml-4 rounded-lg">
                        Visit Our Store
                    </button>
                </div>
                <div className="lg:w-1/2 p-4">
                    <img src={banner01} alt="Car Service" className="w-full rounded-lg shadow-lg" />
                </div>

            </div>
        </section>
    );
}

export default HeroSection;
